import { BiCopyright } from "react-icons/bi"

const Footer = () => {
    let date = new Date();
    return (
    <div className="footer" style={{position: 'sticky', top: '98vh', color:'black', marginLeft:'15px'}}>
        <div>Copyright <BiCopyright/> Arete Advisors {date.getFullYear()}</div>
    </div>
    );
}

export default Footer;