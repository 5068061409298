import React, { useEffect, useState, useCallback } from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import Tables from '../components/Tables';
import Api from '../helper/Api';
import { getAccessToken } from '../helper/ApiHelpers';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedTemplate } from "@azure/msal-react";

function Victims() {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || localStorage.getItem('account'));
    const [victimsData, setVictimsData] = useState(null);
    const [count, setCount] = useState(null);
    const navigate = useNavigate();

    const getVictimsData = useCallback(async () => {
        await getAccessToken(instance, account);
        Api.get('/api/v1/victims?limit=25&ordering=-first_seen_datetime').then(res => {
            setVictimsData(res.data.results);
            setCount(res.data.count)
        }).catch(err => {
            if (err.permissions === "Action not allowed") {
                navigate("/");
                navigate("/unauthorized");
                localStorage.clear();
            }
            console.error(err);
        });
    }, [instance, account, navigate])

    useEffect(() => {
        getVictimsData();
    // eslint-disable-next-line 
    }, [])


    return (
            <div className="victims">
                <AuthenticatedTemplate>
                    <Tables siteData={victimsData} count={count} tableHeader="Victims" orderBy={'-first_seen_datetime'}/>
                </AuthenticatedTemplate>
            </div>
    )
};

export default Victims;