import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";

const TablePagination = ({
    pagesCount,
    currentPage,
    handlePageClick,
    handlePreviousClick,
    handleNextClick,
    handleFirstClick,
    handleLastClick
  }) => {
    useEffect(() => {
      setMaxPages();
    }, [currentPage, handleNextClick, handlePageClick, handlePreviousClick, handleFirstClick, handleLastClick])

    
    const setMaxPages = () => {
      let paginationNumbers = [];
      if (pagesCount) {
          let showMax = 5;
          let endPage;
          let startPage;
  
          if (pagesCount <= showMax) {
              startPage = 0;
              endPage = pagesCount;
          }
          else {
              let endVal = 1;
              if (currentPage !== 0) {
                if (currentPage === 1) {
                  startPage = currentPage - 1;
                  endVal = 2;
                } else {
                  startPage = currentPage - 2;
                  endVal = 3;
                } 
              } else {
                startPage = currentPage;
              }

              if (currentPage !== pagesCount && (currentPage + 1) !== pagesCount) {
                if (currentPage + showMax - 1 > pagesCount) {
                  endPage = pagesCount - 1;
                } else {
                  endPage = currentPage + showMax - endVal;
                }
              } else {
                endPage = currentPage;
              }
          }
          for (let i = startPage; i <= endPage; i++) {
            if (i < pagesCount) {
              paginationNumbers.push(i);
            }
          }
          return (paginationNumbers.map((page) => (
            <PaginationItem active={page === currentPage} key={page}>
              <PaginationLink onClick={(e) => handlePageClick(e, page)} href="#">
                {page + 1}
              </PaginationLink>
            </PaginationItem>
          )))
      }
    }

    return (
      /**The reactstrap Pagination component encapsulates the reactstrap PaginationItem which in turn encapsulates reactstrap PaginationLink. */
      /**The first PaginationItem inside the Pagination is the previous button. This is disabled when the current page is zero or less
       *  than zero “disabled={currentPage <= 0}”. */
      <div>
        <Pagination size="sm">
          <PaginationItem disabled={currentPage <= 0}>
            <PaginationLink onClick={(e) => handleFirstClick(e, 0)} first href="#" />
          </PaginationItem>
          <PaginationItem disabled={currentPage <= 0}>
            <PaginationLink onClick={(e) => handlePreviousClick(e, currentPage)} previous href="#" />
          </PaginationItem>
          {/* The next PaginationItem after the previous PaginationItem button is the dynamic PaginationItem. This is the one that generates the page number buttons. */}
          {/* “Array(pagesCount)”: creates and initializes a new array object of length equal to pagesCount. */}
          {/* “[…Array(pagesCount)].map( fn)”: using the spread operator I expand the array. After expanding, the map() method then creates a new array of PaginationItems. */}
  
          {setMaxPages()}
  
          <PaginationItem disabled={currentPage >= pagesCount - 1}>
            <PaginationLink onClick={(e) => handleNextClick(e, currentPage)} next href="#" />
          </PaginationItem>
          <PaginationItem disabled={currentPage >= pagesCount - 1}>
            <PaginationLink onClick={(e) => handleLastClick(e, pagesCount-1)} last href="#" />
          </PaginationItem>
        </Pagination>
      </div>
    );
};

TablePagination.propTypes = {
    pagesCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    handlePageClick: PropTypes.func.isRequired,
    handlePreviousClick: PropTypes.func.isRequired,
    handleNextClick: PropTypes.func.isRequired,
    handleFirstClick: PropTypes.func.isRequired,
    handleLastClick: PropTypes.func.isRequired
};
export default TablePagination;