import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID,
      authority: "https://login.microsoftonline.com/" + process.env.REACT_APP_TENANT_ID, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: process.env.REACT_APP_REDIRECT_URL,
      postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URL
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    // system: {	
    //   loggerOptions: {	
    //       loggerCallback: (level, message, containsPii) => {	
    //           if (containsPii) {		
    //               return;		
    //           }		
    //           switch (level) {		
    //               case LogLevel.Error:		
    //                   console.error(message);		
    //                   return;		
    //               case LogLevel.Info:		
    //                   console.info(message);		
    //                   return;		
    //               case LogLevel.Verbose:		
    //                   console.debug(message);		
    //                   return;		
    //               case LogLevel.Warning:		
    //                   console.warn(message);		
    //                   return;		
    //           }	
    //       }	
    //   }
    // }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: [
      'User.Read',
      'Mail.Read',
      'Offline_access'
    ]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me/"
  };
