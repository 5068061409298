import './App.css';
import Sites from './pages/Sites';
import Victims from './pages/Victims';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Login from './pages/Login';
import React, { useEffect } from 'react';
import WithoutNav from './helper/WithoutNav';
import WithNav from './helper/WithNav';
import EditSites from './pages/EditSites';
import Unauthorized from './pages/Unauthorized';
import PageNotFound from './pages/PageNotFound';
import { useIsAuthenticated } from '@azure/msal-react';

function App() {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated && !localStorage.getItem('account')) {
      navigate('/');
    }
  }, [navigate, isAuthenticated])

  return (
    <div className="App">
      <Routes>
        <Route element={<WithoutNav />}>
            <Route path="/" exact element={<Login />} />
            <Route path="/unauthorized" element={<Unauthorized />}/>
        </Route>
          <Route element={<WithNav />}>
            <Route path="/sites" element={<Sites />} />
            <Route path="/sites/edit" element={<EditSites />} />
            <Route path="/victims" element={<Victims />}/>
          </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
