import Api from "./Api";

const getTokenSilently = async (instance, account) => {
    let response = await instance.acquireTokenSilent({
        scopes: [
            'User.Read',
            'Mail.Read',
            'Offline_access'
        ],
        account: account
    });
    return response;
}

const getAccessToken = async (instance, account) => {
    if (localStorage.getItem('access_token')) {
        return localStorage.getItem('access_token');
    }
    if (account) {
        localStorage.setItem('account', JSON.stringify(account));
    }
    if (account || localStorage.getItem('account')) {
        let userAcc = JSON.parse(localStorage.getItem('account'));
        let response = await getTokenSilently(instance, userAcc);

        if(response) {
            try {
                let res = await Api.post(`/arete-auth/microsoft/login`, {'access_token': response.accessToken});
                let data = res.data;
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('refresh_token', data.refresh_token);
                return data.access_token; 
            } catch(error) {
                console.error(error);
            }
        }
    }
};

export {getAccessToken};