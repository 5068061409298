import React from 'react';
import Sidebar from '../components/Sidebar';
import { Outlet } from 'react-router';
import handleLogout from '../components/SignOutButton';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Col } from "reactstrap"
import { useState } from 'react';
import { AiOutlineUser } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useAccount, useMsal } from "@azure/msal-react";
import '../index.css';
import Footer from '../components/Footer';

export default () => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || JSON.parse(localStorage.getItem('account')));
    const navigate = useNavigate();

    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }

    const styles = {
        contentDiv: {
            height: '100vh',
            display: 'flex',
            overflow: 'hidden' 
        },
        contentMargin: {
            marginTop: 5
        },
    };

    return (
        <>
            <div style={styles.contentDiv}>
                <div style={{fontFamily: 'Lato'}}>
                    <Sidebar />
                </div>
                <div style={{width: '100%', overflowY: 'auto', fontFamily: 'Lato'}}>
                    <div style={{height: '56px', backgroundColor: '#041E42', width: '100%', position: 'sticky', top: 0, zIndex: 1000}}>
                        <div style={{display: 'flex', marginTop: 8, float: 'right', textAlign: 'right'}}>
                            <Col className="accName" sm={8} style={{ marginTop: 8,  color: 'white', minWidth: 500, marginRight: 10}}>
                                {account && account.idTokenClaims.preferred_username}
                            </Col>
                            <Col sm={1} className="userDropdown">
                                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                    <DropdownToggle caret style={{backgroundColor: 'transparent', color: 'white', borderColor: 'transparent'}}>
                                        <AiOutlineUser style={{color: 'white'}} />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => handleLogout(instance, navigate)}>Logout</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </div>
                    </div>
                    <div style={styles.contentMargin}>
                        <Outlet />
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
};