import React, {useEffect, useState, useCallback} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMsal, useAccount } from "@azure/msal-react";
import { Row, Form, FormGroup, FormFeedback,Label, Input, Col } from 'reactstrap';
import Button from "react-bootstrap/Button";
import Api from '../helper/Api';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { getAccessToken } from '../helper/ApiHelpers';

const EditSites = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || localStorage.getItem('account'));
    const [siteName, setSiteName] = useState("");
    const [taUrl, setTaUrl] = useState("");
    const [taHeaders, setTaHeaders] = useState("");
    const [threatActor, setThreatActor] = useState("");
    const [siteStatus, setSiteStatus] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const dropDownList = ['conti', 'revil', 'avaddon', 'darkside', 'babuk', 'ranzy', 'astro', 'pay2key', 'cuba', 'ransomexx','mount', 'ragnarok', 'ragnar', 
    'suncrypt', 'everest', 'nefilim', 'cl0p', 'pysa', 'lockbit', 'xing', 'lorenz', 'elcometa', 'arvin', 'blackmatter', 'avoslocker', 'lv', 'marketo', 
    'lockdata', 'hive', 'rook', 'snatch', 'alphavm', 'hellokitty', 'zeppelin', 'karakurt', 'blackbyte', 'blackcat', 'quantum', 'sfile', 'cheers', 'redalert', 'daixin'];
    const [badUrl, setBadUrl] = useState("");
    const [errorMessage, setErrorMessage] = useState('');

    const getSitesData = useCallback(async () => {
        await getAccessToken(instance, account);
        Api.get(`/api/v1/sites/${location.state.siteId}`).then(res => {
            setSiteName(res.data.site_name);
            setTaUrl(res.data.ta_url);
            setThreatActor(res.data.threat_actor);
            setSiteStatus(res.data.site_status);
            setTaHeaders(res.data.ta_headers)
        })
    }, [instance, account, location.state.siteId])

    useEffect(() => {
        getSitesData();
    }, [getSitesData])


    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }

    const statusToggle = () => {
        setStatusDropdownOpen(!statusDropdownOpen);
    }

    const updateFilter = (event) => {
        setThreatActor(event.target.innerHTML);
    }

    const renderDropdownItems = () => {
        return dropDownList.map((key, index) => {
            return <DropdownItem key={index} className={threatActor === key ? "active" : "inactive"} onClick={e => updateFilter(e)}>{key}</DropdownItem>
        });
    }

    const changeName = (e) => {
        setSiteName(e.target.value);
    }

    const changeTaHeaders = (e) => {
        setTaHeaders(e.target.value);
    }

    const changeUrl = (e) => {
        if(!e.target.value.startsWith('https://') && !e.target.value.startsWith('http://')){
            setBadUrl("Url must start with 'http://' or 'https://'");
        } else if(!e.target.value.includes('.onion')){
            setBadUrl("Url must contain '.onion'");
        } else if(e.target.value.endsWith('/')){
            setBadUrl("Url must not end with '/'");
        } else {
            setBadUrl("");
        }
        setTaUrl(e.target.value);
    }

    const stopEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    const updateData = (e) => {
        let body = {
            site_name: siteName,
            threat_actor: threatActor,
            ta_url: taUrl,
            site_status: siteStatus,
            ta_headers: taHeaders
        }

        Api.put(`/api/v1/sites/${location.state.siteId}`, body).then(res => {
            e.preventDefault();
            navigate('/sites');
        }).catch(err => {
            if(err.site_name){
                setErrorMessage('Error editing this site: ' + err.site_name);
            } else {
                setErrorMessage('An error occurred please try again');
            }
        });
    }


    return (
        <Form>
            <FormGroup row>
                <Label for="siteName" style={styles.labelStyle} sm={2}>Site Name</Label>
                <Col sm={4}>
                    <Input type="text" onKeyPress={stopEnter} value={siteName} onChange={changeName} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="taUrl" style={styles.labelStyle} sm={2}>TA Url</Label>
                <Col style={{float: 'left'}} sm={4}>
                    <Input type="text" onKeyPress={stopEnter} value={taUrl} onChange={changeUrl} invalid={badUrl !== ""}/>
                    <FormFeedback>{badUrl}</FormFeedback>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="siteStatus" style={styles.labelStyle} sm={2}>Site Status</Label>
                <Col sm={4}>
                    <Dropdown style={{float: 'left'}} isOpen={statusDropdownOpen} toggle={statusToggle}>
                    <DropdownToggle caret style={{backgroundColor: 'transparent', color: 'black', borderColor: 'transparent'}}>
                            {siteStatus}
                        </DropdownToggle>
                        <DropdownMenu style={{height:'75px'}}>
                            <DropdownItem key='0' className={siteStatus === 'online' ? "active" : "inactive"} onClick={e => setSiteStatus(e.target.innerHTML)}>online</DropdownItem>
                            <DropdownItem key='1' className={siteStatus === 'offline' ? "active" : "inactive"} onClick={e => setSiteStatus(e.target.innerHTML)}>offline</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="taHeaders" style={styles.labelStyle} sm={2}>TA Headers</Label>
                <Col style={{float: 'left'}} sm={4}>
                    <Input type="text" onKeyPress={stopEnter} value={taHeaders} onChange={changeTaHeaders}/>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="threatActor" style={styles.labelStyle} sm={2}>Threat Actor</Label>
                <Col sm={4}>
                    <Dropdown style={{float:'left'}} isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle caret style={{backgroundColor: 'transparent', color: 'black', borderColor: 'transparent'}}>
                            {threatActor}
                        </DropdownToggle>
                        <DropdownMenu style={{height:'250px', overflowY:'scroll'}}>
                            {renderDropdownItems()}
                        </DropdownMenu>
                    </Dropdown>
                </Col>
            </FormGroup>
            <Row>
                <Col style={{float: 'left'}} sm={6}>
                {errorMessage !== '' && <p style={{color: 'red'}}>{errorMessage}</p>}
                </Col>
            </Row>
            <Row>
                <Col sm={2}></Col>
                <Col sm={4}>
                    <Button variant="primary" className="ml-auto" style={{float: 'left', marginTop: 10}} onClick={updateData} disabled={badUrl !== ""}>Submit</Button>
                </Col>
            </Row>
        </Form>

    )
}

const styles = {
    labelStyle: {
        fontWeight: 'bold',
        float: 'left'
    },
    inputStyle: {
        width: '20%'
    },
    formError: {
        color: 'red !important',
    }
}

export default EditSites;