import { useAccount, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Row, Form, FormGroup, FormFeedback,Label, Input, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Api from "../helper/Api";
import { getAccessToken } from '../helper/ApiHelpers';

function EditSitesModal(props) {
    const [show, setShow] = useState(false);
    const [editSite, setEditSite] = useState({site_name: '', ta_url: '', threat_actor: '', site_status: '', ta_headers: ''})
    const [badUrl, setBadUrl] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const dropDownList = ['alphavm','arvin','astro','avaddon','avoslocker','babuk','blackbyte','blackcat','blackmatter','cheers','cl0p','conti','cuba','daixin','darkside','elcometa',
    'everest','hellokitty','hive','karakurt','lockbit','lockdata','lorenz','lv','marketo','mount','nefilim','pay2key','pysa','quantum','ragnar','ragnarok',
    'ransomexx','ranzy','redalert','revil','rook','sfile','snatch','suncrypt','vicesociety','xing','zeppelin'];
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || localStorage.getItem('account'));

    useEffect(() => {
        setShow(props.show);
        setEditSite({site_name: props.site.site_name, ta_url: props.site.ta_url, threat_actor: props.site.threat_actor, site_status: props.site.site_status, ta_headers: props.site.ta_headers});
        // eslint-disable-next-line 
    }, [props.show]);

    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }

    const statusToggle = () => {
        setStatusDropdownOpen(!statusDropdownOpen);
    }

    const updateFilter = (event) => {
        let prevEdit = editSite;
        setEditSite({site_name: prevEdit.site_name, ta_url: prevEdit.ta_url, threat_actor: event.target.innerHTML, site_status: prevEdit.site_status, ta_headers: prevEdit.ta_headers})
    }

    const renderDropdownItems = () => {
        return dropDownList.map((key, index) => {
            return <DropdownItem key={index} className={editSite.threat_actor === key ? "active" : "inactive"} onClick={e => updateFilter(e)}>{key}</DropdownItem>
        });
    }

    const changeName = (e) => {
        let prevEdit = editSite;
        setEditSite({site_name: e.target.value, ta_url: prevEdit.ta_url, threat_actor: prevEdit.threat_actor, site_status: prevEdit.site_status, ta_headers: prevEdit.ta_headers});
    }

    const changeSiteStatus = (e) => {
        let prevEdit = editSite;
        setEditSite({site_name: prevEdit.site_name, ta_url: prevEdit.ta_url, threat_actor: prevEdit.threat_actor, site_status: e.target.innerHTML, ta_headers: prevEdit.ta_headers});
    }

    const changeTaHeaders = (e) => {
        let prevEdit = editSite;
        setEditSite({site_name: prevEdit.site_name, ta_url: prevEdit.ta_url, threat_actor: prevEdit.threat_actor, site_status: prevEdit.site_status, ta_headers: e.target.value})
    }

    const changeUrl = (e) => {
        if(!e.target.value.startsWith('https://') && !e.target.value.startsWith('http://')){
            setBadUrl("Url must start with 'http://' or 'https://'");
        } else if(!e.target.value.includes('.onion')){
            setBadUrl("Url must contain '.onion'");
        } else if(e.target.value.endsWith('/')){
            setBadUrl("Url must not end with '/'");
        } else {
            setBadUrl("");
        }
        let prevEdit = editSite;
        setEditSite({site_name: prevEdit.site_name, ta_url: e.target.value, threat_actor: prevEdit.threat_actor, site_status: prevEdit.site_status, ta_headers: prevEdit.ta_headers})
    }

    const stopEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    const updateData = async (e) => {
        let body = {
            site_name: editSite.site_name,
            threat_actor: editSite.threat_actor,
            ta_url: editSite.ta_url,
            site_status: editSite.site_status,
            ta_headers: editSite.ta_headers
        }

        await getAccessToken(instance, account);
        Api.put(`/api/v1/sites/${props.site.id}`, body).then(res => {
            props.onHide(res.data);
        }).catch(err => {
            if(err.site_name){
                setErrorMessage('Error editing this site: ' + err.site_name);
            } else {
                setErrorMessage('An error occurred please try again');
            }
        });
    }

    return (
        <>
            <Form>
                <Modal show={show} onHide={props.onHide} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Site: {editSite.site_name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup row>
                            <Label for="siteName" style={styles.labelStyle} sm={4}>Site Name</Label>
                            <Col sm={8}>
                                <Input type="text" onKeyPress={stopEnter} value={editSite.site_name} onChange={changeName} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="taUrl" style={styles.labelStyle} sm={4}>TA Url</Label>
                            <Col style={{float: 'left'}} sm={8}>
                                <Input type="text" onKeyPress={stopEnter} value={editSite.ta_url} onChange={changeUrl} invalid={badUrl !== ""}/>
                                <FormFeedback>{badUrl}</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="siteStatus" style={styles.labelStyle} sm={4}>Site Status</Label>
                            <Col sm={8}>
                                <Dropdown style={{float: 'left'}} isOpen={statusDropdownOpen} toggle={statusToggle}>
                                <DropdownToggle caret style={{backgroundColor: 'transparent', color: 'black', borderColor: 'transparent'}}>
                                        {editSite.site_status}
                                    </DropdownToggle>
                                    <DropdownMenu style={{height:'75px'}}>
                                        <DropdownItem key='0' className={editSite.site_status === 'online' ? "active" : "inactive"} onClick={e => changeSiteStatus(e)}>online</DropdownItem>
                                        <DropdownItem key='1' className={editSite.site_status === 'offline' ? "active" : "inactive"} onClick={e => changeSiteStatus(e)}>offline</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="taHeaders" style={styles.labelStyle} sm={4}>TA Headers</Label>
                            <Col style={{float: 'left'}} sm={8}>
                                <Input type="text" onKeyPress={stopEnter} value={editSite.ta_headers} onChange={changeTaHeaders}/>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="threatActor" style={styles.labelStyle} sm={4}>Threat Actor</Label>
                            <Col sm={8}>
                                <Dropdown style={{float:'left'}} isOpen={dropdownOpen} toggle={toggle}>
                                    <DropdownToggle caret style={{backgroundColor: 'transparent', color: 'black', borderColor: 'transparent'}}>
                                        {editSite.threat_actor}
                                    </DropdownToggle>
                                    <DropdownMenu style={{height:'250px', overflowY:'scroll'}}>
                                        {renderDropdownItems()}
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </FormGroup>
                        <Row>
                            <Col style={{float: 'left'}} sm={6}>
                            {errorMessage !== '' && <p style={{color: 'red'}}>{errorMessage}</p>}
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.onHide}>
                            Close
                        </Button>
                        <Button variant="primary" className="ml-auto" style={{float: 'left'}} onClick={updateData} disabled={badUrl !== ""}>Submit</Button>
                    </Modal.Footer>
                </Modal>
            </Form>
        </>
    )
}

const styles = {
    labelStyle: {
        fontWeight: 'bold',
        float: 'left'
    },
    inputStyle: {
        width: '20%'
    },
    formError: {
        color: 'red !important',
    }
}

export default EditSitesModal;