import { ProSidebar, Menu, MenuItem, SidebarHeader } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { GoDashboard } from 'react-icons/go';
import { MdPersonSearch } from 'react-icons/md'
import 'react-pro-sidebar/dist/css/styles.css';
import { useState } from 'react';
import './Sidebar.scss';
import { FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi';
import { VscMenu } from 'react-icons/vsc';
import '../index.css';
import { Navbar } from 'reactstrap';

const Sidebar = () => {

    const [menuCollapse, setMenuCollapse] = useState(false);
    const rightArrow = "/rightArrow.svg";
    const leftArrow = "/leftArrow.svg"
    
    const sideColl = () => {
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    }

    return (
        <>
            <ProSidebar id="sidebar" collapsed={menuCollapse}>
                <SidebarHeader>
                    <div style={{display: 'flex', alignItems: 'center', marginLeft: 20}}>
                    <img id="areteA" className="areteLogo" src="/arete_a.svg" alt="Arete"/>
                        <p id="textNav" className="navText" style={{fontFamily: 'Lato'}}>RansomWatch</p>
                    </div>
                </SidebarHeader>
                <Menu iconShape="square">
                    <MenuItem icon={<MdPersonSearch />} style={{color: 'white'}}>
                        Victims
                        <Link to="/victims" />
                    </MenuItem>
                    <MenuItem icon={<GoDashboard />} style={{color: 'white'}}>
                        Sites
                        <Link to="/sites" />
                    </MenuItem>
                </Menu>
            </ProSidebar>
            <div onClick={sideColl}>
                    {menuCollapse ?  <img className="openMenu" src={leftArrow}/> : <img className="closeMenu" src={rightArrow}/>}
            </div>
        </>
    )
}

export default Sidebar;
