import { useIsAuthenticated } from '@azure/msal-react';
import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignInButton } from "../components/SignInButton";

const styles = {
    loginHeader: {
        backgroundColor: "#041E42",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: '"10px" + "2vmin"',
        color: "white"
    },
    login: {
        textAlign: "center"
    }
};

function Login() {
    const navigate = useNavigate();
    const isAuthenicated = useIsAuthenticated();

    useEffect(() => {
        if (localStorage.getItem('account') || isAuthenicated) {
            navigate('/victims')
        }
    }, [navigate, isAuthenicated])

    return(
        <div className="login-page">
            <header className="login-header" style={styles.loginHeader}>
                <p>
                    RansomWatch Login
                </p>
                <SignInButton />
            </header>
      </div>
    )
};

export default Login;