import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authProvider";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";


/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance } = useMsal();
    const navigate = useNavigate();
    
    const handleLogin = async () => {
        try {
            let loginResponse = await instance.loginPopup(loginRequest)
            if (loginResponse.account) {
                navigate("/victims");
            }
        } catch (err) {
            console.error(err)
        }
    };

    return (
        <div className="signIn">
            <Button variant="primary" className="ml-auto" onClick={() => handleLogin()}>Arete SSO </Button>
        </div>
    );
}