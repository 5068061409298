import React from 'react';

function Unauthorized() {
    return (
        <div>
            <h2>Incorrect Permissions: Ransomwatch</h2>
            <h3>For access, please reach out to the development team at: <b>innovation@areteir.com</b></h3>
        </div>
    )
};

export default Unauthorized;
