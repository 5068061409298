import axios from 'axios';

let Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const getRefreshToken = () => {
  return Api.post('/arete-auth/token/refresh', {'refresh': localStorage.getItem('refresh_token')});
}

Api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Prevent infinite loops
      if (err.response.status === 401 && originalConfig.url === '/arete-auth/token/refresh') {
        window.location.href = '/';
        localStorage.removeItem('account');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token');
        return Promise.reject(err);
      }
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry && originalConfig.url !== '/arete-auth/microsoft/login') {
        originalConfig._retry = true;
        try {
          let rs = await getRefreshToken();
          localStorage.setItem('access_token', rs.data.access);
          localStorage.setItem('refresh_token', rs.data.refresh);
          Api.defaults.headers.common['Authorization'] = `Bearer ${rs.data.access}`;
          return Api(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      }
      if (err.response.status === 401 && originalConfig.url === '/arete-auth/microsoft/login' && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          return Api(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
      if (err.response.status === 400 && err.response.data) {
        return Promise.reject(err.response.data);
      }
      if (err.response.status === 401 && originalConfig._retry) {
        localStorage.removeItem('account');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token');
        window.location.href = '/unauthorized';
        return Promise.reject(err);
      }
    }
    return Promise.reject(err);
  }
);

export default Api;