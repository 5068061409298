import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Api from "../helper/Api";

function handleLogout(instance, navigate) {
    instance.logoutRedirect({onRedirectNavigate: (url) => {
        // Return false if you would like to stop navigation after local logout
        return false;
    }}).then(response => {
        Api.post('arete-auth/logout', {'refresh': localStorage.getItem('refresh_token')}).then(res => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('account');
            navigate("/");
        }).catch(err => {
            console.error(err);
        });
    }).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
const SignOutButton = () => {
    const { instance } = useMsal();
    const navigate = useNavigate();

    return (
        <Button variant="primary" className="ml-auto" onClick={() => handleLogout(instance, navigate)}>Logout</Button>
    );
};

export default handleLogout;