import React, { useCallback, useEffect, useState } from 'react';
import Tables from '../components/Tables';
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useMsal, useAccount } from "@azure/msal-react";
import Api from '../helper/Api';
import { getAccessToken } from '../helper/ApiHelpers';
import { useNavigate } from 'react-router-dom';

function Sites() {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || localStorage.getItem('account'));
    const [sitesData, setSitesData] = useState(null);
    const [count, setCount] = useState(null);
    const navigate = useNavigate();
    
    const getSitesData = useCallback(async () => {
        await getAccessToken(instance, account);
        Api.get('api/v1/sites?limit=25&ordering=-site_status').then(res => {
            setSitesData(res.data.results);
            setCount(res.data.count);
        }).catch(err => {
            if (err.permissions === "Action not allowed") {
                navigate("/");
                navigate("/unauthorized");
                localStorage.clear();
            }
            console.error(err);
        });
    }, [instance, account, navigate])

    useEffect(() => {
        getSitesData();
    // eslint-disable-next-line 
    }, [])


    const scrapeSite = (siteId) => {
        let body = {
            site_id: siteId,
            type_name: "watch"
        };
        Api.post(`api/v1/actions/new`, body).then(res => {
            getSitesData();
        }).catch(err => {
            console.error("Couldn't scrape this site: " + err);
        });
    }

    return (
        // Adding AuthenticationTemplate here allows you to protect your component by demanding a user signin. We can also add a redirect here if they don't sign in.
        <div className="sitesPage">
            <AuthenticatedTemplate>
                    <Tables siteData={sitesData} tableHeader="Sites" count={count} scraper={scrapeSite} orderBy={'-site_status'} />
            </AuthenticatedTemplate>
        </div>
    )
};

export default Sites;