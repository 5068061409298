import Api from '../helper/Api';

const taOptions = async () => {
    let res = await Api.get(`/api/v1/sites?ordering=site_name`)
    let data = res.data.results;
    let arr = [];
    
    for(let i = 0; i < data.length; i++){
        arr[i] = { value: data[i].id, label: data[i].site_name};
    }
    return arr;
}


export {taOptions};