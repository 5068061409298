import React, { useEffect, useState, useRef } from "react";
import { Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';
import { BiFilterAlt } from 'react-icons/bi';
import { BsSearch } from 'react-icons/bs';
import TablePagination from "./TablePagination";
import { IconContext } from "react-icons";
import { CSVLink } from "react-csv";
import { FaRegCopy } from "react-icons/fa";
import { FiEdit, FiDownload } from "react-icons/fi";
import { MdOutlinePlayCircleOutline } from "react-icons/md";
import { confirm } from "react-confirm-box";
import { taOptions } from "./filterData";
import Api from '../helper/Api';
import useWindowDimensions from "./useWindowDimensions";
import ReactTooltip from "react-tooltip";
import "./Tables.css";
import "../index.css";
import EditSitesModal from "./EditSitesModal";

const Tables = (props) => {
    const [siteData, setSiteData] = useState(null);
    const [sitesInfo, setSitesInfo] = useState(null);
    const [pageLink, setPageLink] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const dropdownNumbers = [15, 25, 50, 100];
    const [numberKey, setNumberKey] = useState(25);
    const [tableCount, setTableCount] = useState(null);
    const [columnOrder, setColumnOrder] = useState(props.orderBy);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [csvState, setCsvState] = useState([{}]);
    const [taArray, setTaArray] = useState();
    const [checkedState, setCheckedState] = useState();
    const [filterChecked, setFilterChecked] = useState([]);
    const [taString, setTaString] = useState('');
    const [siteStatusOn, setSiteStatusOn] = useState(false);
    const [siteStatusOff, setSiteStatusOff] = useState(false);
    const [siteToggleOn, setSiteToggleOn] = useState('/Toggle_off.svg');
    const [siteToggleOff, setSiteToggleOff] = useState('/Toggle_off.svg');
    const {height, width} = useWindowDimensions();
    const [exportVar, setExportVar] = useState(true);
    const csvLink = useRef();
    const [show, setShow] = useState(false);
    const [editSite, setEditSite] = useState({site_name: '', ta_url: '', threat_actor: '', site_status: '', ta_headers: ''});

    useEffect(() => {
        if (siteData === null) {
            setSiteData(props.siteData);
        }
        setTableCount(props.count);
        setPageLink(props.tableHeader);
        getSites();
        setupFilter();
        if (filterChecked.length > 0 && filterChecked !== null){
            setupFilteredData(siteData);
        }
        // eslint-disable-next-line 
    }, [props.siteData, props.count, localStorage.getItem('access_token')])

    const handleClose = (editSite) => {
        setShow(false);
        let newSiteData = siteData;
        if (editSite) {
            setSiteData(newSiteData.map(site => {
                if (site.id === editSite.id) {
                    return editSite
                }
                return site
            }))
        }
    };

    const handleShow = (result) => {
        setEditSite({id: result.id, site_name: result.site_name, ta_url: result.ta_url, threat_actor: result.threat_actor, site_status: result.site_status, ta_headers: result.ta_headers});
        setShow(true);
    };

    const getSites = () => {
        if (props.tableHeader === 'Victims' && localStorage.getItem('access_token')) {
            Api.get('api/v1/sites?limit=1000').then(res => {
                setSitesInfo(res.data.results);
            });
        }
    }

    const setupFilteredData = (victimData) => {
        if (victimData !== null && props.tableHeader === 'Victims' && victimData !== undefined) {
            victimData.sort((a, b) => a.site_id - b.site_id);
        }
        return victimData;
    }

    const setupFilter = async () => {
        let arr = await taOptions();
        setTaArray(arr);
        if (taString.length === 0 && filterChecked !== null) {
            setCheckedState(new Array(arr.length).fill(false));
        }
    }

    const handleApiCalls = () => {
        let filterSearch = '';
        if(taString !== ''){
            filterSearch=`&site_id__in=${taString}`;
        }
        if(taString !== '' && searchValue !== ''){
            filterSearch=`&search=${searchValue}&site_id__in=${taString}`;
        }
        if(siteStatusOn === true && siteStatusOff === true) {
            filterSearch=`&site_status__in=online,offline`;
        }
        if(siteStatusOn === true && siteStatusOff === false) {
            filterSearch=`&site_status__in=online`;
        }
        if(siteStatusOn === false && siteStatusOff === true) {
            filterSearch=`&site_status__in=offline`;
        }
        if(searchValue !== '' && siteStatusOn === true && siteStatusOff === true) {
            filterSearch=`&search=${searchValue}&site_status__in=online,offline`;
        }
        if(searchValue !== '' && siteStatusOn === true && siteStatusOff === false) {
            filterSearch=`&search=${searchValue}&site_status__in=online`;
        }
        if(searchValue !== '' && siteStatusOn === false && siteStatusOff === true) {
            filterSearch=`&search=${searchValue}&site_status__in=offline`;
        }
        if(searchValue !== ''){
            filterSearch=`&search=${searchValue}`;
        }
        return filterSearch;
    }

    const handlePageClick = (e, index) => {
        e.preventDefault();
        setCurrentPage(index);
        let apiLink = props.tableHeader.toLowerCase();
        let offsetNum = numberKey*index;
        let filterSearch = handleApiCalls();
        if(columnOrder !== '') {
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&offset=${offsetNum}${filterSearch}`).then(res => {
                let data = res.data.results;
                if (filterChecked.length > 0 && filterChecked !== null) {
                    data = setupFilteredData(data);
                };
                setSiteData(data);
            });
        }
        else {
            Api.get(`/api/v1/${apiLink}?limit=${numberKey}&offset=${offsetNum}${filterSearch}`).then(res => {
                let data = res.data.results;
                if (filterChecked.length > 0 && filterChecked !== null) {
                    data = setupFilteredData(data);
                };
                setSiteData(data);
            });
        }
     };

     const handleFirstClick = (e, index) => {
        e.preventDefault();
        setCurrentPage(index);
        let apiLink = props.tableHeader.toLowerCase();
        let offsetNum = numberKey*index;
        let filterSearch = handleApiCalls();
        if(columnOrder !== '') {
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&offset=${offsetNum}${filterSearch}`).then(res => {
                let data = res.data.results;
                if (filterChecked.length > 0 && filterChecked !== null) {
                    data = setupFilteredData(data);
                };
                setSiteData(data);
            });
        }
        else {
            Api.get(`/api/v1/${apiLink}?limit=${numberKey}&offset=${offsetNum}${filterSearch}`).then(res => {
                let data = res.data.results;
                if (filterChecked.length > 0 && filterChecked !== null) {
                    data = setupFilteredData(data);
                };
                setSiteData(data);
            });
        }
     };

     const handleLastClick = (e, index) => {
        e.preventDefault();
        setCurrentPage(index);
        let apiLink = props.tableHeader.toLowerCase();
        let offsetNum = numberKey*index;
        let filterSearch = handleApiCalls();
        if(columnOrder !== '') {
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&offset=${offsetNum}${filterSearch}`).then(res => {
                let data = res.data.results;
                if (filterChecked.length > 0 && filterChecked !== null) {
                    data = setupFilteredData(data);
                };
                setSiteData(data);
            });
        }
        else {
            Api.get(`/api/v1/${apiLink}?limit=${numberKey}&offset=${offsetNum}${filterSearch}`).then(res => {
                let data = res.data.results;
                if (filterChecked.length > 0 && filterChecked !== null) {
                    data = setupFilteredData(data);
                };
                setSiteData(data);
            });
        }
     };

     const handlePreviousClick = (e, index) => {
        e.preventDefault();
        setCurrentPage(index-1);
        let apiLink = props.tableHeader.toLowerCase();
        let offsetNum = numberKey*(index-1);
        let filterSearch = handleApiCalls();
        if(columnOrder !== '') {
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&offset=${offsetNum}${filterSearch}`).then(res => {
                let data = res.data.results;
                if (filterChecked.length > 0 && filterChecked !== null) {
                    data = setupFilteredData(data);
                };
                setSiteData(data);
            });
        }
        else {
            Api.get(`/api/v1/${apiLink}?limit=${numberKey}&offset=${offsetNum}${filterSearch}`).then(res => {
                let data = res.data.results;
                if (filterChecked.length > 0 && filterChecked !== null) {
                    data = setupFilteredData(data);
                };
                setSiteData(data);
            });
        }
     };

     const handleNextClick = (e, index) => {
        e.preventDefault();
        setCurrentPage(index+1);
        let apiLink = props.tableHeader.toLowerCase();
        let offsetNum = numberKey*(index+1);
        let filterSearch = handleApiCalls();
        if(columnOrder !== '') {
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&offset=${offsetNum}${filterSearch}`).then(res => {
                let data = res.data.results;
                if (filterChecked.length > 0 && filterChecked !== null) {
                    data = setupFilteredData(data);
                };
                setSiteData(data);
            });
        }
        else {
            Api.get(`/api/v1/${apiLink}?limit=${numberKey}&offset=${offsetNum}${filterSearch}`).then(res => {
                let data = res.data.results;
                if (filterChecked.length > 0 && filterChecked !== null) {
                    data = setupFilteredData(data);
                };
                setSiteData(data);
            });
        };
     };

     const addSorting = (header, key) => {
        if (header !== 'status' && header !== 'data leak in:') {
            return (<div style={{display: 'flex'}}>
                <span onClick={() => sortTableData(key, 'asc')}>
                    <TiArrowSortedUp />
                </span>
                <span style={{paddingBottom: 5}} onClick={() => sortTableData(key, 'dsc')}>
                    <TiArrowSortedDown />
                </span>
            </div>)
        } else {
            return <div></div>
        }
     }

     const setupDates = (victimObj) => {
        if (props.tableHeader === 'Victims' && siteData !== null) {
            victimObj.forEach(victim => {
                let timeOfLeak = '--';
                victim['data leak in:'] = '--';
                if (victim.victim_published_datetime !== null) {
                    // get total seconds between the times
                    let delta = Math.abs(new Date(victim.victim_published_datetime) - new Date(victim.first_seen_datetime)) / 1000;

                    // calculate (and subtract) whole days
                    let days = Math.floor(delta / 86400);
                    delta -= days * 86400;

                    // calculate (and subtract) whole hours
                    let hours = Math.floor(delta / 3600) % 24;
                    delta -= hours * 3600;
                    if (String(hours).length < 2) {
                        hours = '0' + hours;
                    }

                    // calculate (and subtract) whole minutes
                    let minutes = Math.floor(delta / 60) % 60;
                    if (String(minutes).length < 2) {
                        minutes = '0' + minutes;
                    }
                    delta -= minutes * 60;
                    timeOfLeak = days + ' days | ' + hours + ' : ' + minutes
                }
                if (new Date(victim.last_updated_datetime) > new Date(victim.victim_published_datetime) && victim.victim_published_datetime !== null) {
                    victim['status'] = 'Data Leaked';
                } else if (new Date() < new Date(victim.victim_published_datetime)) {
                    victim['data leak in:'] = timeOfLeak;
                    victim['status'] = 'Pending Leak';
                } else if (new Date(victim.last_updated_datetime) <= new Date() && victim.victim_published_datetime === null) {
                    // WORKING LOGIC TO FIGURE OUT IF VICTIM HAS BEEN PUBLISHED FOR MORE THAN 30 DAYS, IF SO. MARK AS DATA REMOVED
                    let now = new Date()
                    let msBetweenDates = now - new Date(victim.first_seen_datetime);
                    // 👇️ convert ms to days                 hour   min  sec   ms
                    const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
                    if (daysBetweenDates > 30) {
                        victim['status'] = 'Data Removed';
                    } else {
                        victim['status'] = 'Pending Leak'
                    }
                } else if (new Date(victim.last_updated_datetime) < new Date() && new Date(victim.last_updated_datetime) < new Date(victim.victim_published_datetime)) {
                    victim['status'] = 'Data Removed';
                }
            })
        }
    }

    const renderTableHeader = () => {
        if (props.siteData) {
            setupDates(siteData);
            let header = Object.keys(props.siteData[0])
            let victimUrlPop = header.splice(3, 1);
            header.push(victimUrlPop[0]);
            return header.map((key, index) => {
                let widthSize = '160px';
                let defaultStyle;
                if (key.length <= 3) {
                    widthSize = '80px'
                }
                if (key === 'site_status') {
                    widthSize = '128px'
                }
                if (key === 'first_seen_offline_datetime') {
                    widthSize = '160px'
                }
                if (key === 'ta_url' || key === 'victim_url') {
                    defaultStyle = {fontSize: '12px', width: '192px'}
                } else if (key === 'site_name' && width >= 600) {
                    defaultStyle = {fontSize: '12px', width: widthSize, left: '80px', zIndex: '700'}
                } else if (key === 'id' && width >= 600) {
                    defaultStyle = {fontSize: '12px', width: '80px', left: '0', zIndex: '700'}
                } else if (key === 'victim_name' && width >= 600) {
                    defaultStyle = {fontSize: '12px', width: '192px', minWidth: '192px', left: '160px', zIndex: '700'}
                } else if (key === 'site_id' && props.tableHeader === 'Victims' && width >= 600) {
                    defaultStyle = {fontSize: '12px', width: '160px', minWidth: '160px', left: '0', zIndex: '700'}
                } else {
                    defaultStyle = {fontSize: '12px', width: widthSize}
                }
                let header = key.replace('_', ' ');
                header = header.replace('_datetime', '');
                header = header.replace('_by', ' by');
                if(header === 'created by' || header === 'last modified by' || header === 'last updated' || header === 'victim published'){
                    return null;
                }
                if (props.tableHeader === 'Victims' && header === 'id') {
                    return null;
                }
                if (header === 'site id') {
                    header = 'threat actor'
                }
                if (header === 'first seen') {
                    header = 'date first seen'
                }
                if (header === 'first seen_offline') {
                    header = 'first offline date'
                }
                if (header === 'victim url') {
                    header = 'data url'
                }
                if (header === 'fuzzy match') {
                    header = 'arete client'
                }
                if (header === 'fuzzy str_match') {
                    header = 'client name'
                }
                return <th key={index} style={defaultStyle}>
                    <span style={{display: 'flex', overflowWrap: 'anywhere', width: widthSize}}>
                        <div>{header.toUpperCase()}</div>
                        {addSorting(header, key)}
                    </span>
                    {header === 'data leak in:' && <div style={{display: 'flex', overflowWrap: 'anywhere', width: widthSize}}>
                        Days | Hrs : Mins
                    </div> }
                </th>
            })
        }
    }

    const offlineSite = (index) => {
        return(
        <IconContext.Provider value={{className: "offline-icon", size:15 , color: 'red'}}>
            <>
                <td key={index} style={{fontSize:'14px', color: 'red', textAlignLast: 'start'}}>
                    offline
                </td>
            </>
        </IconContext.Provider>
        );
    }

    const renderTableBody = (rowData) => {
        if (siteData) {
            let data = Object.values(rowData);
            let dataUrlPop = data.splice(3, 1);         
            let headers = Object.keys(rowData);
            let headersUrlPop = headers.splice(3, 1);
            data.push(dataUrlPop[0]);
            headers.push(headersUrlPop[0]);
            let defaultClassName = 'noStyle';
            let alignBubble = 'leftAlign';

            return data.map((key, index) => {
                if (key === 'Data Removed') {
                    defaultClassName = 'dataRemoved bgDataRemoved';
                    alignBubble = 'centerAlign'
                }
                else if (key === 'Pending Leak') {
                    defaultClassName = 'pendingLeak bgPenLeak';
                    alignBubble = 'centerAlign'
                }
                else if (key === 'Data Leaked') {
                    defaultClassName = 'dataLeaked bgDataLeaked';
                    alignBubble = 'centerAlign'
                }
                else if (headers[index] === 'victim_url') {
                    defaultClassName = 'noStyle';
                    alignBubble = 'leftAlign';
                }
                else {
                    if(props.tableHeader === "Victims"){
                        defaultClassName = 'tdAlign';
                    }
                }
                if ((headers[index] === 'first_seen_datetime' || headers[index] === 'first_seen_offline_datetime') && key !== null) {
                    let date = new Date(key).toLocaleString('en-us', {month: 'long', day: 'numeric', year: 'numeric'});
                    let dateTime = new Date(key).toLocaleString('en-us', {hour: 'numeric', minute: 'numeric'})
                    return <td style={{fontSize: '14px', textAlign: 'left', overflowWrap: 'anywhere'}} key={index}><div className={defaultClassName}>{date}</div><div>{dateTime}</div></td>
                }
                if (headers[index] === 'site_status' && key === 'offline'){
                    return offlineSite(index);
                }
                if (headers[index] === 'created_by' || headers[index] === 'last_modified_by' || headers[index] === 'last_updated_datetime' || headers[index] === 'victim_published_datetime') {
                    return null;
                }
                if (headers[index] === 'id' && props.tableHeader === 'Victims') {
                    return null;
                }
                if (headers[index] === 'site_id' && props.tableHeader === 'Victims') {
                    if (sitesInfo !== null) {
                        sitesInfo.forEach(item => {if (key === item.id) {key = item.site_name}})
                    }
                }
                if (headers[index] === 'id' && key !== null) {
                    return <td className='freezeCol' style={{fontSize: '14px', overflowWrap: 'anywhere', background: 'white'}} key={index}>
                        <span className={defaultClassName}>{key}</span></td>
                }
                if (headers[index] === 'site_name' && key !== null) {
                    return <td className='freezeCol2' style={{fontSize: '14px', overflowWrap: 'anywhere', background: 'white'}} key={index}>
                        <span className={defaultClassName}>{key}</span></td>
                }
                if (headers[index] === 'victim_name' && key !== null) {
                    if(key.length >= 40) {
                        let newKey = key.substring(0,39) + '...';
                        return <td className='freezeCol3' style={{fontSize: '14px', overflowWrap: 'anywhere', background: 'white'}} key={index}>
                            <ReactTooltip className="tooltip2" clickable={true} id="toolTip7" delayHide={500} type='info' backgroundColor="#041E42" effect='solid'/>
                            <span data-tip={key} data-for='toolTip7' data-place='left' className={defaultClassName}>{newKey}</span></td>
                    }
                    return <td className='freezeCol3' style={{fontSize: '14px', overflowWrap: 'anywhere', background: 'white'}} key={index}>
                        <span className={defaultClassName}>{key}</span></td>
                }
                if (headers[index] === 'site_id' && key !== null && props.tableHeader === "Victims") {
                    return <td className='freezeCol' style={{fontSize: '14px', overflowWrap: 'anywhere', background: 'white'}} key={index}>
                        <span className={defaultClassName}>{key}</span></td>
                }
                if (headers[index] === 'ta_headers' && key !== null && key.length >= 35) {
                    let newKey = key.substring(0,34) + '...';
                    return <td className={alignBubble} style={{fontSize: '14px', overflowWrap: 'anywhere'}}>
                        <ReactTooltip className="tooltip2" clickable={true} id="toolTip4" delayHide={500} type='info' backgroundColor="#041E42" effect='solid'/>
                        <span data-tip={key} data-for='toolTip4' data-place='right' className={defaultClassName}>{newKey}</span></td>
                }
                if (headers[index] === 'victim_url' && key !== null && key.length >= 27) {
                    let newKey = key.substring(0,26) + '...';
                    return <td className={alignBubble} style={{fontSize: '14px', overflowWrap: 'anywhere'}}>
                        <ReactTooltip className="tooltip2" clickable={true} id="toolTip5" delayHide={500} type='info' backgroundColor="#041E42" effect='solid'/>
                        <span data-tip={key} data-for='toolTip5' data-place='left' className={defaultClassName}>{newKey}</span></td>
                }
                if (headers[index] === 'ta_url' && key !== null && key.length >= 29) {
                    let newKey = key.substring(0,28) + '...';
                    return <td className={alignBubble} style={{fontSize: '14px', overflowWrap: 'anywhere'}}>
                        <ReactTooltip className="tooltip2" clickable={true} id="toolTip6" delayHide={500} type='info' backgroundColor="#041E42" effect='solid'/>
                        <span data-tip={key} data-for='toolTip6' data-place='left' className={defaultClassName}>{newKey}</span></td>
                }
                return <td className={alignBubble} style={{fontSize: '14px', overflowWrap: 'anywhere'}} key={index}>
                    <span className={defaultClassName}>{key}</span></td>
            });
        }
    }

    const sortTableData = (column, direction) => {
        let apiLink = pageLink.toLowerCase();
        let limit = siteData.length;
        let order = `${column}`;
        if (direction === 'dsc') {
            order = `-${column}`
        }
        let filterSearch = handleApiCalls();
        if(currentPage+1 === Math.ceil(tableCount && tableCount / numberKey)) {
            limit = numberKey;
        }

        if(column === "site_id") {
            order = 'site_id__threat_actor'
            if (direction === 'dsc') {
                order = '-site_id__threat_actor';
            }
        }

        if(column === "fuzzy_match"){
            order = 'confidence_score';
            if (direction === 'dsc') {
                order = '-confidence_score';
            }
        }
        setColumnOrder(order);
        Api.get(`/api/v1/${apiLink}?ordering=${order}&limit=${limit}${filterSearch}`).then(res => {
            setSiteData(res.data.results);
            setCurrentPage(0);
        });
    }

    const searchForResults = () => {
        let apiLink = pageLink.toLowerCase();
        let filterSearch = handleApiCalls();
        if (taString !== '') {
            Api.get(`/api/v1/${apiLink}?limit=${numberKey}&ordering=${columnOrder}&search=${searchValue}&site_id__in=${taString}`).then(res => {
                setCurrentPage(0);
                let data = res.data.results;
                if (filterChecked.length > 0 && filterChecked !== null) {
                    data = setupFilteredData(data);
                };
                setSiteData(data);
                setTableCount(res.data.count);
            })
        } else if (siteStatusOn === true && siteStatusOff === true) {
            Api.get(`/api/v1/${apiLink}?limit=${numberKey}&ordering=${columnOrder}&search=${searchValue}&site_status__in=online,offline`).then(res => {
                setCurrentPage(0);
                let data = res.data.results;
                if (filterChecked.length > 0 && filterChecked !== null) {
                    data = setupFilteredData(data);
                };
                setSiteData(data);
                setTableCount(res.data.count);
            })
        } else if (siteStatusOn === true && siteStatusOff === false) {
            Api.get(`/api/v1/${apiLink}?limit=${numberKey}&ordering=${columnOrder}&search=${searchValue}&site_status__in=online`).then(res => {
                setCurrentPage(0);
                let data = res.data.results;
                if (filterChecked.length > 0 && filterChecked !== null) {
                    data = setupFilteredData(data);
                };
                setSiteData(data);
                setTableCount(res.data.count);
            })
        } else if (siteStatusOn === false && siteStatusOff === true) {
            Api.get(`/api/v1/${apiLink}?limit=${numberKey}&ordering=${columnOrder}&search=${searchValue}&site_status__in=offline`).then(res => {
                setCurrentPage(0);
                let data = res.data.results;
                if (filterChecked.length > 0 && filterChecked !== null) {
                    data = setupFilteredData(data);
                };
                setSiteData(data);
                setTableCount(res.data.count);
            })
        } 
        else {
            Api.get(`/api/v1/${apiLink}?limit=${numberKey}&ordering=${columnOrder}${filterSearch}`).then(res => {
                setCurrentPage(0);
                let data = res.data.results;
                if (filterChecked.length > 0 && filterChecked !== null) {
                    data = setupFilteredData(data);
                };
                setSiteData(data);
                setTableCount(res.data.count);
            });
        }
    }

    const updateNumResults = (event) => {
        let apiLink = pageLink.toLowerCase();
        let lim = event.target.innerHTML;
        setNumberKey(event.target.innerHTML);
        let filterSearch = handleApiCalls();
        Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${lim}${filterSearch}`).then(res => {
            let data = res.data.results;
            if (filterChecked.length > 0 && filterChecked !== null) {
                data = setupFilteredData(data);
            };
            setSiteData(data);
            setTableCount(res.data.count);
            setCurrentPage(0);
        });
    }

    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }

    const toggle2 = () => {
        setDropdownOpen2(!dropdownOpen2);
    }

    const renderDropdownItems = () => {
        return (
            <div>
                <ul className="filterDropDown">
                    {taArray && checkedState && taArray.map(({ label, value}, index) => {
                        return (
                            <li key={index}>
                                <input
                                    type="checkbox"
                                    id={`custom-checkbox-${index}`}
                                    name={label}
                                    value={label}
                                    checked={checkedState[index]}
                                    onChange={() => handleOnChange(index)}
                                    
                                />
                                <label style={{marginLeft:'5px'}}htmlFor={`custom-checkbox-${index}`}>{label}</label>
                            </li>
                        );
                    })}
                </ul>
            </div>
        )
    }

    const renderDropdownNumbers = () => {
        return dropdownNumbers.map((key, index) => {
            return <DropdownItem key={index} className={parseInt(numberKey) === key ? "active" : "inactive"} onClick={e => updateNumResults(e)}>{key}</DropdownItem>
        });
    }

    const exportResults = async () => {
        setExportVar(false);
        let saveNext = true;
        let csvData = [];
        let csvCount = 0;
        let apiLink = pageLink.toLowerCase();
        let filterSearch = handleApiCalls();
        while(saveNext) {
            let res = await Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=1000&offset=${csvCount}${filterSearch}`)
            res.data.results.forEach(item => {csvData.push(item)});
            csvCount = csvCount + 1000;
            if(res.data.next === null) {
                saveNext = false;
            }
        }
        setExportVar(true);
        setCsvState(csvData);
        csvLink.current.link.click()
    }

    const showEntries = () => {
        let endNum = numberKey;
        if(tableCount < endNum) {
            endNum = tableCount;
        }
        if(currentPage === 0){
            return <p>Showing 1-{endNum} of {tableCount} results</p>
        } else {
            let newEnd = endNum * (currentPage+1);
            if(newEnd > tableCount){
                newEnd = tableCount;
            }
            return <p>Showing {(currentPage * numberKey) + 1}-{newEnd} of {tableCount} results</p>
        }
    }

    const scrapeConfirm = async (options, result) => {
        const results = await confirm("Do you want to scrape this site?", options);
        if(results){
            props.scraper(result.id);
            return;
        }
    };

    const closeOverlayClick = {
        closeOnOverlayClick: true
    };

    const getCsvData = () => {
        return csvState;
    }

    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedState(updatedCheckedState);
        let string1 = '';
        let tempArr = [];
        const totalString = updatedCheckedState.reduce(
            (string1, currentState, index) => {
                if (currentState === true) {
                    tempArr.push(taArray[index].label);
                    return string1 + taArray[index].value + ',';
                }
                return string1;
            },
            string1
        );
        setTaString(totalString);
        let apiLink = pageLink.toLowerCase();
        if(searchValue !== '') {
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&search=${searchValue}&site_id__in=${totalString}`).then(res => {
                handleChangeAPI(res, tempArr);
            })
        } else {
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&site_id__in=${totalString}`).then(res => {
                handleChangeAPI(res, tempArr);
            })
        }
    }

    const handleChangeAPI = (res, tempArr) => {
        let data = res.data.results;
        if (tempArr.length > 0 && tempArr !== null) {
            data = setupFilteredData(data);
        };
        setSiteData(data);
        setTableCount(res.data.count);
        setCurrentPage(0);
        setFilterChecked(tempArr)
    }

    const handleOnlineStatus = () => {
        let apiLink = pageLink.toLowerCase();

        if (searchValue !== '') {
            if(siteStatusOn === false && siteStatusOff === true) {
                setSiteStatusOn(true);
                setSiteToggleOn('Toggle_On.svg');
                Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&search=${searchValue}&site_status__in=online,offline`).then(res => {
                    let data = res.data.results;
                    setSiteData(data);
                    setTableCount(res.data.count);
                    setCurrentPage(0);
                })
            } else if(siteStatusOn === true && siteStatusOff === true) {
                setSiteStatusOn(false);
                setSiteToggleOn('Toggle_off.svg');
                Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&search=${searchValue}&site_status__in=offline`).then(res => {
                    let data = res.data.results;
                    setSiteData(data);
                    setTableCount(res.data.count);
                    setCurrentPage(0);
                })
            } else if (siteStatusOn === false) {
                setSiteStatusOn(true);
                setSiteToggleOn('Toggle_On.svg');
                Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&search=${searchValue}&site_status__in=online`).then(res => {
                    let data = res.data.results;
                    setSiteData(data);
                    setTableCount(res.data.count);
                    setCurrentPage(0);
                })
            } else if(siteStatusOn === true){
                setSiteStatusOn(false);
                setSiteToggleOn('Toggle_off.svg');
                Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&search=${searchValue}`).then(res => {
                    let data = res.data.results;
                    setSiteData(data);
                    setTableCount(res.data.count);
                    setCurrentPage(0);
                })
            }
        } else if(siteStatusOn === false && siteStatusOff === true) {
            setSiteStatusOn(true);
            setSiteToggleOn('Toggle_On.svg');
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&site_status__in=online,offline`).then(res => {
                let data = res.data.results;
                setSiteData(data);
                setTableCount(res.data.count);
                setCurrentPage(0);
            })
        } else if(siteStatusOn === true && siteStatusOff === true) {
            setSiteStatusOn(false);
            setSiteToggleOn('Toggle_off.svg');
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&site_status__in=offline`).then(res => {
                let data = res.data.results;
                setSiteData(data);
                setTableCount(res.data.count);
                setCurrentPage(0);
            })
        } else if(siteStatusOn === false) {
            setSiteStatusOn(true);
            setSiteToggleOn('Toggle_On.svg');
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&site_status__in=online`).then(res => {
                let data = res.data.results;
                setSiteData(data);
                setTableCount(res.data.count);
                setCurrentPage(0);
            })
        } else if(siteStatusOn === true) {
            setSiteStatusOn(false);
            setSiteToggleOn('Toggle_off.svg');
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}`).then(res => {
                let data = res.data.results;
                setSiteData(data);
                setTableCount(res.data.count);
                setCurrentPage(0);
            })
        }
    }

    const handleOfflineStatus = () => {
        let apiLink = pageLink.toLowerCase();

        if (searchValue !== '') {
            if(siteStatusOn === true && siteStatusOff === false) {
                setSiteStatusOff(true);
                setSiteToggleOff('Toggle_On.svg');
                Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&search=${searchValue}&site_status__in=online,offline`).then(res => {
                    let data = res.data.results;
                    setSiteData(data);
                    setTableCount(res.data.count);
                    setCurrentPage(0);
                })
            } else if(siteStatusOn === true && siteStatusOff === true) {
                setSiteStatusOff(false);
                setSiteToggleOff('Toggle_off.svg');
                Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&search=${searchValue}&site_status__in=online`).then(res => {
                    let data = res.data.results;
                    setSiteData(data);
                    setTableCount(res.data.count);
                    setCurrentPage(0);
                })
            } else if (siteStatusOff === false) {
                setSiteStatusOff(true);
                setSiteToggleOff('Toggle_On.svg');
                Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&search=${searchValue}&site_status__in=offline`).then(res => {
                    let data = res.data.results;
                    setSiteData(data);
                    setTableCount(res.data.count);
                    setCurrentPage(0);
                })
            } else if(siteStatusOff === true){
                setSiteStatusOff(false);
                setSiteToggleOff('Toggle_off.svg');
                Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&search=${searchValue}`).then(res => {
                    let data = res.data.results;
                    setSiteData(data);
                    setTableCount(res.data.count);
                    setCurrentPage(0);
                })
            }
        } else if(siteStatusOn === true && siteStatusOff === false) {
            setSiteStatusOff(true);
            setSiteToggleOff('Toggle_On.svg');
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&site_status__in=online,offline`).then(res => {
                let data = res.data.results;
                setSiteData(data);
                setTableCount(res.data.count);
                setCurrentPage(0);
            })
        } else if(siteStatusOn === true && siteStatusOff === true) {
            setSiteStatusOff(false);
            setSiteToggleOff('Toggle_off.svg');
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&site_status__in=online`).then(res => {
                let data = res.data.results;
                setSiteData(data);
                setTableCount(res.data.count);
                setCurrentPage(0);
            })
        } else if(siteStatusOff === false) {
            setSiteStatusOff(true);
            setSiteToggleOff('Toggle_On.svg');
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&site_status__in=offline`).then(res => {
                let data = res.data.results;
                setSiteData(data);
                setTableCount(res.data.count);
                setCurrentPage(0);
            })
        } else if(siteStatusOff === true) {
            setSiteStatusOff(false);
            setSiteToggleOff('Toggle_off.svg');
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}`).then(res => {
                let data = res.data.results;
                setSiteData(data);
                setTableCount(res.data.count);
                setCurrentPage(0);
            })
        }
    }

    const callOnChange = (name) => {
        taArray.forEach((ta, index) => {
            if(ta.label === name) {
                handleOnChange(index);
            }
        })
    }

    const showClear = () => {
        if(props.tableHeader === "Victims" && taString !== '') {
            return <div className="bgFilterClear" onClick={handleClear}>Clear</div>
        }
    }

    const handleClear = () => {
        const updatedCheckedState = checkedState.map((item, index) =>
            item === true ? !item : item
        );
        
        setCheckedState(updatedCheckedState);
        let empty = '';
        let emptyArr = [];
        setTaString(empty);
        setFilterChecked(emptyArr);
        let apiLink = pageLink.toLowerCase();
        if(searchValue !== '') {
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}&search=${searchValue}`).then(res => {
                    setSiteData(res.data.results);
                    setTableCount(res.data.count);
                    setCurrentPage(0);
            })
        } else {
            Api.get(`/api/v1/${apiLink}?ordering=${columnOrder}&limit=${numberKey}`).then(res => {
                setSiteData(res.data.results);
                setTableCount(res.data.count);
                setCurrentPage(0);
            })
        }
    }

    const findTaUrl = () => {
        let taurl = false;
        let url = 'victim_url';
        if(props.tableHeader === 'Sites') {
            url = 'ta_url';
        }
        if(props.siteData) {
            Object.keys(props.siteData[0]).forEach(header => {
                if(header === url){
                    taurl = true;
                    return taurl;
                }
            })
            return taurl;
        }
    }

    const handleOnKeyUp = (e) => {
        if(e.key === 'Enter') {
            searchForResults();
        }
    }

    const copyDataUrl = (index, victim) => {
        if (props.tableHeader === 'Victims' && siteData !== null) {
            navigator.clipboard.writeText(victim[index].victim_url);
        }
    }
    return (
        <div className="topDiv">
            <h1 className="tableHeader">{props.tableHeader}</h1>
            <div className="btnSearchDiv">
                <div className="csvDiv">
                    {siteData && exportVar &&
                        <div>
                            <button className="button btn-primary csvBtn" onClick={exportResults}>Download <FiDownload className="dwnld" style={{marginBottom: '5px'}}/></button>
                            <CSVLink
                                data={getCsvData()}
                                filename={`${pageLink}${new Date().toISOString()}.csv`}
                                className="hidden"
                                ref={csvLink}
                                target="_blank"
                                >
                            </CSVLink>
                        </div>
                    }
                    {siteData && !exportVar && 
                        <div>
                            <button className="button btn-primary csvBtn2">Downloading</button>
                        </div>
                    }
                </div>
                <div className="filterDiv">
                    {props.tableHeader === "Victims" && <div className="flex-row-reverse bubbleFilters">
                            {filterChecked.map((key, index) => {
                                return <div className="bgFilter" onClick={() => callOnChange(key)}>{key} X</div>
                            })}
                        </div>}
                    {showClear()}
                    <div className="filterSearchDiv">
                        {props.tableHeader === "Victims" && <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle caret style={{backgroundColor: 'transparent', color: 'black', borderColor: 'transparent'}}>
                                <BiFilterAlt style={{color: 'black'}} />
                            </DropdownToggle>
                            <DropdownMenu>
                                {renderDropdownItems()}
                            </DropdownMenu>
                        </Dropdown>}
                        {props.tableHeader === "Sites" &&
                            <div style={{display: 'flex'}}>
                                <img src={siteToggleOn} alt="toggle" onClick={handleOnlineStatus}/><p style={{marginTop: '5px', marginRight: '10px'}}>Online</p>
                            </div>
                        }
                        {props.tableHeader === "Sites" && 
                            <div style={{display: 'flex'}}>
                                <img className="offlineToggle" src={siteToggleOff} alt="toggle2" onClick={handleOfflineStatus}/><p className="offlineP" style={{marginTop: '5px'}}>Offline</p>
                            </div>
                        }
                        <input type="text" onKeyUp={handleOnKeyUp} placeholder="Search" value={searchValue} className="searchBar" onChange={e => setSearchValue(e.target.value)}></input>
                        <div onClick={searchForResults} className="searchBtn">
                            <BsSearch />
                        </div>
                    </div>
                </div>
            </div>
            <div className="tableDiv">
                <Table hover striped bordered size="sm" className="tableStyle">
                    <thead style={{fontFamily: "Lato ui-sans-serif"}}>
                        <tr>
                            {renderTableHeader()}
                            {findTaUrl() && props.tableHeader === 'Sites' && <th className="actionsCol"><span><div style={{marginBottom: '5px'}}>ACTIONS</div></span></th>}
                            {findTaUrl() && props.tableHeader === 'Victims' && <th className="actionsCol"><span><div>ACTIONS</div></span></th>}
                        </tr>
                    </thead>
                    <tbody>
                        {siteData && siteData.map((result, index) => {
                            let tempSiteName = siteData[0].site_name;
                            let tempGroupLineName = null;
                            if (sitesInfo !== null && props.tableHeader === 'Victims' && filterChecked !== null && filterChecked.length > 0) {
                                sitesInfo.forEach(site => {
                                    if (site.id === result.site_id) {
                                        if (result !== siteData[0]) {
                                            if (site.id !== siteData[index - 1].site_id) {
                                                tempSiteName = site.site_name
                                                tempGroupLineName = <tr className='victimGroup' key={tempSiteName + 'group'}><td colSpan={'12'}>{tempSiteName.charAt(0).toUpperCase() + tempSiteName.slice(1)}</td></tr>;
                                            } else {
                                                tempGroupLineName = ''
                                            }
                                        } else {
                                            tempSiteName = site.site_name
                                            tempGroupLineName = <tr className='victimGroup' key={tempSiteName + 'group'}><td colSpan={'12'}>{tempSiteName.charAt(0).toUpperCase() + tempSiteName.slice(1)}</td></tr>;
                                        }
                                    }
                                })
                            }
                            return (
                                <>
                                    {tempGroupLineName}
                                    <tr key={index}>
                                        {renderTableBody(result)}
                                        {findTaUrl() && props.tableHeader === 'Sites' &&
                                            <td className="editScrapeTd" key={index}>       
                                                <button data-tip="Edit Site" data-for='toolTip1' data-place='bottom' style={{backgroundColor: 'transparent', border: 0}} onClick={() => handleShow(result)}>
                                                    <FiEdit />
                                                </button>
                                                <ReactTooltip className="tooltip" id="toolTip1" type='info' backgroundColor="#041E42" effect='solid'/>
                                                <button data-tip="Scrape Site" data-for='toolTip2' data-place='bottom' style={{backgroundColor: 'transparent', border: 0}} onClick={() => {scrapeConfirm(closeOverlayClick, result)}}>
                                                    <MdOutlinePlayCircleOutline />
                                                </button>
                                                <ReactTooltip className="tooltip" id="toolTip2" type='info' backgroundColor="#041E42" effect='solid'/>
                                            </td>
                                        }
                                        {findTaUrl() && props.tableHeader === 'Victims' &&
                                            <td className="copyTd" key={index}>
                                                <button data-tip="Copy URL" data-for='toolTip3' data-place='bottom' style={{backgroundColor: 'transparent', border: 0}} onClick ={() => {copyDataUrl(index, siteData)}}>
                                                    <FaRegCopy />
                                                </button>
                                                <ReactTooltip className="tooltip" id="toolTip3" type='info' backgroundColor="#041E42" effect='solid'/>
                                            </td>
                                        }
                                    </tr>
                                </>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            <div className="divBelowTable">
                <div className="paginationDiv">
                    <TablePagination className="pagination"
                        pagesCount={Math.ceil(tableCount && tableCount / numberKey)}
                        currentPage={currentPage}
                        handlePageClick={handlePageClick}
                        handlePreviousClick={handlePreviousClick}
                        handleNextClick={handleNextClick}
                        handleFirstClick={handleFirstClick}
                        handleLastClick={handleLastClick}
                    />
                </div>
                <div className="entriesDiv">
                    {showEntries()}
                </div>
                <div className="entriesDivTwo">
                    <Dropdown className="entriesDropDown" isOpen={dropdownOpen2} toggle={toggle2}>
                        <DropdownToggle className="btn-dark" caret style={{backgroundColor: 'transparent', color: 'black', borderColor: 'transparent'}}>
                            {numberKey}
                        </DropdownToggle>
                        <DropdownMenu>
                            {renderDropdownNumbers()}
                        </DropdownMenu>
                    </Dropdown>
                    <p className="entriesP">Entries per page: </p>
                </div>
            </div>
            <EditSitesModal show={show} onHide={handleClose} site={editSite} />
        </div>
    );
}

export default Tables;